@font-face
  font-family: Geraldine
  src: url(../public/fonts/geraldine/GeraldinePersonalUseItalic-PK12m.ttf)

html
  font-size: 10px

.split
  flex: 1

.links
  background-color: #f3ebaf
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: stretch
  width: 600px
  padding: 2rem
  box-sizing: border-box
  font-size: 2.4rem
  white-space: nowrap
  max-width: 100%
  gap: 1rem
  > .invite
    display: flex
    > .name
      overflow: hidden
      margin-right: 2rem
      flex: 1
    > .copy
      cursor: pointer
      &:hover
        color: white

.fallback
  min-height: 100vh

#root
  background-color: #f3ebaf
  background-image: url(../public/backgrounds/yellow-flowers.jpg)
  display: flex
  flex-direction: column
  align-items: center

  >.body
    overflow: hidden
    user-select: none
    box-sizing: border-box
    text-align: center
    max-width: 100%
    padding: 0 2rem

    flex: 1
    display: flex
    // gap: 2.4rem
    // padding-bottom: 32px

    flex-direction: column
    align-items: stretch
    background-color: #f3ebafd0
    color: #db8a2e
    // color: #695722
    justify-content: center

    font-family: "Tinos", serif
    &.Japanese
      font-family: "Noto Serif JP", serif
      font-optical-sizing: auto

.header
  font-family: Geraldine
  font-size: 4.4rem
  .Russian &
    font-size: 4.8rem
    font-family: "Great Vibes", cursive
  .names
    padding: 0.4rem 0
    color: #5bb876
    font-size: 4.5rem
    .Russian &
      font-size: 5rem

.date
  font-family: "Gwendolyn", cursive
  font-size: 4rem
  margin-bottom: 16px
  font-weight: bold
  .Russian &
    font-family: "Great Vibes", cursive
    font-size: 4.8rem
    font-weight: initial

.venue
  font-size: 3rem
  align-self: stretch
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  >img
    max-width: 100%
    width: 600px
    margin-bottom: 1.6rem

  > .description
    max-width: 100%
    width: 600px
    padding: 0 3.2rem
    box-sizing: border-box

  >.subtitle
    font-size: 2.4rem

.divider
  margin: 3.2rem 0
  width: 64%
  align-self: center
  box-sizing: border-box
  max-width: 320px
  &.negative
    background-color: red
  > img
    width: 100%
    display: block

.venue
  > .subtitle

  >.description
    font-size: 2rem
    margin-bottom: 1.6rem
    &.continue
      margin-bottom: 0.4rem

  // &:not(:last-child)
  //   margin-bottom: 24px

  >.title
    font-family: "Gwendolyn", cursive
    font-size: 4rem
    line-height: 4rem
    margin-bottom: 0.8rem
    font-weight: bold
    border-bottom: 0.2rem solid #5bb876
    .Russian &
      font-family: "Great Vibes", cursive
      font-weight: initial

.invitation
  min-height: 100vh
  box-sizing: border-box
  min-height: calc(var(--vh, 1vh) * 100)
  order: -1

.section
  display: flex
  flex-direction: column
  justify-content: center
  &.top, &.bottom
    margin: 0 -2rem

.map
  position: relative
  max-height: 90vh
  max-width: 600px
  width: 100%

  >iframe
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    height: 100%
    width: 100%
    border: none

  &::before
    content: ''
    display: block
    padding-bottom: 100%

.slider
  overflow: hidden
  position: relative
  align-self: center
  > img
    display: block
    max-height: 80vh
    max-width: 100%
    opacity: 0

  &.vertical
    max-width: 683px
    >img
      max-height: 80vh

  &.horizontal
    // max-width: 1080px
    >img
      max-width: 100vw

  >.inner
    position: absolute
    bottom: 0
    left: 0
    right: 0
    top: 0
    overflow-x: scroll

    display: flex
    transition: transform 0.5s ease
    scroll-behavior: smooth
    scroll-snap-type: x mandatory

    -ms-overflow-style: none
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

    img
      width: auto
      height: auto
      max-height: 80vh
      max-width: 100%
      // opacity: 0
      transition: opacity 0.5s ease
      transition-delay: 0.5s
      scroll-snap-align: start

      &.selected
        transition: none
        opacity: 1

  > .arrow
    position: absolute
    top: 0
    bottom: 0
    display: flex
    padding: 1rem
    background-color: #fff4
    align-items: center
    visibility: hidden
    cursor: pointer
    &.hidden
      display: none

    &.left
      padding-left: 1.3rem
      left: 0
      &::before
        transform: rotateZ(-45deg)
    &.right
      padding-right: 1.3rem
      right: 0
      &::before
        transform: rotateZ(135deg)
    &::before
      content: ''
      display: block
      width: 1.2rem
      height: 1.2rem
      border: 0.3rem solid white
      border-bottom: none
      border-right: none
      box-sizing: border-box

  >.dots
    bottom: 0
    left: 0
    right: 0
    height: 20px
    position: absolute
    display: inline-flex
    align-items: center
    justify-content: center
    gap: 10px
    >.dot
      width: 4px
      height: 4px
      background-color: white
      box-shadow: 0px 2px 2px 1px #0004
      border-radius: 50%
      overflow: hidden
      &.selected
        background-color: #ccc
        box-shadow: 0px 2px 2px 1px #0004

.last
  order: 1

@media (min-width: 1024px)
  html
    font-size: 10px
  .slider
    &:hover
      >.arrow
        visibility: visible
  .section
    &.top, &.bottom
      margin: 0
    &.top
      order: 1
    &.bottom
      order: -1
